<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Empleados - Detalle de horas</h4>
            <div class="small text-muted">Detalle de las horas consumidas</div>
          </b-col>
        </b-row>
      </b-card>
      
      <b-row>
        <b-col sm="6">          
          <b-card header-tag="header" footer-tag="footer">
            
            <b-badge variant="dark" v-if="this.employeeSelected">
              Empleado
            </b-badge>
            {{this.employeeSelected.name}}
            
            <FullCalendar defaultView="dayGridMonth" 
                          :plugins="calendarPlugins"                           
                          :locale="calendarOptions.locale"
                          :weekends="true" 
                          :events="calendarOptions.event"
                          :selectable="true" 
                          @dateClick="dateClick "/>
          </b-card>                                      
        </b-col>
        <b-col sm="6">

          <b-card header-tag="header" footer-tag="footer">
            <b-row class="mb-3">
              <b-col md="6">

                <b-badge variant="dark" v-if="this.employeeSelected">
                  Empleado
                </b-badge>
                {{this.employeeSelected.name}}

                <h2>{{moment(this.dateSelected).format('MMMM')}} de 
                    {{moment(this.dateSelected).format('YYYY')}}</h2>
              </b-col>              
            </b-row>
            <b-tabs active-nav-item-class="font-weight-bold text-uppercase text-dark">
              <b-tab title="General">
                <b-row align-h="center">
                  <b-col md="8">
                    <b-table-simple hover small caption-top responsive>
                      <b-thead head-variant="dark">
                        <b-tr>                      
                          <b-th colspan="2" class="text-center">TOTALES</b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <b-tr>
                          <b-th>Proyectos</b-th>
                          <b-td class="text-right">{{this.total.hoursProjectMonth.toFixed(2)}} Hs</b-td>
                        </b-tr>
                        <b-tr>
                          <b-th>Actividades</b-th>
                          <b-td class="text-right">{{this.total.hoursActivityMonth.toFixed(2)}} Hs</b-td>
                        </b-tr>                    
                      </b-tbody>       
                      <b-tfoot>
                        <b-tr>
                          <b-td colspan="7" variant="secondary" class="text-right">
                            Total Trabajado: <b>{{(this.total.hoursProjectMonth + this.total.hoursActivityMonth).toFixed(2)}} Hs</b>
                          </b-td>
                        </b-tr>
                      </b-tfoot>                             
                    </b-table-simple>
                  </b-col>    
                </b-row>
              </b-tab>
              <b-tab title="Detalle">
                <b-row align-h="center">
                  <b-col md="8">
                    <PieChart
                      v-if="chart.loaded"
                      :chartData="chart.chartdata"
                    />            
                    <b-alert v-else show variant="info">
                      <h4 class="alert-heading">Sin Registros</h4>
                      <p>
                        No se encontraron datos.
                      </p>
                    </b-alert>                
                  </b-col>         
                </b-row>         
              </b-tab>
            </b-tabs>            
          </b-card>

          <b-card header-tag="header" footer-tag="footer">
            <b-row class="mb-3">
              <b-col md="6">

                <b-badge variant="dark" v-if="this.employeeSelected">
                  Empleado
                </b-badge>
                {{this.employeeSelected.name}}

                <h2>{{moment(this.dateSelected).format('DD')}} de 
                    {{moment(this.dateSelected).format('MMMM')}} de 
                    {{moment(this.dateSelected).format('YYYY')}}</h2>
              </b-col>              
            </b-row>
            
            <b-row>
              <b-col md="12">                        
                <b-table class="mb-0"
                        responsive="sm"
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="false"
                        :items="table.items"
                        :fields="table.fields"
                        v-if="table.items.length">
                    
                    <template v-slot:table-colgroup="scope">
                      <col
                        v-for="field in scope.fields"                    
                        :key="field.key"
                        :style="{ width: field.width }"
                      >
                    </template>  

                    <template v-slot:cell(assignment)="data">
                      <div v-if="data.item.projects_id">
                        <b>Proyecto:</b> {{ data.item.projects.name }} <br>  
                        <b>Cliente:</b> {{ data.item.projects.customer.name}}
                      </div>                      
                      <div v-else>                        
                        {{ data.item.activity.name }}
                      </div>                                                                  
                    </template>
                  
                    <template v-slot:cell(description)="data">
                      <div>
                        {{ data.item.description }}
                      </div>
                    </template>

                    <template v-slot:cell(hours)="data">
                      <b>{{ data.item.hours }} hs</b>
                    </template>

                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>                
              </b-col>
            </b-row>            

            <b-row class="mt-3" align-h="center" v-if="table.items.length">              
              <b-col md="8" center>
                <b-table-simple hover small caption-top responsive>
                  <b-thead head-variant="dark">
                    <b-tr>                      
                      <b-th colspan="2" class="text-center">TOTALES</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr>
                      <b-th>Proyectos</b-th>
                      <b-td class="text-right">{{this.totalProjects.toFixed(2)}} Hs</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>Actividades</b-th>
                      <b-td class="text-right">{{this.totalActivity.toFixed(2)}} Hs</b-td>
                    </b-tr>                    
                  </b-tbody>       
                  <b-tfoot>
                    <b-tr>
                      <b-td colspan="7" variant="secondary" class="text-right">
                        Total Trabajado: <b>{{(this.totalActivity + this.totalProjects).toFixed(2)}} Hs</b>
                      </b-td>
                    </b-tr>
                  </b-tfoot>                             
                </b-table-simple>                
              </b-col>              
            </b-row>                
          </b-card> 
                        
          <b-card header-tag="header" footer-tag="footer">
            <b-row>
              <b-col>
                <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
                  <i class="fa fa-angle-double-left"></i>
                  Volver
                </b-button>                        
              </b-col>
            </b-row>
          </b-card>                    
        </b-col>
      </b-row>              
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Profiles from '@/config/profiles'
  import Error from '@/handler/error'
  import ErrorToken from '@/handler/errorToken'
  import Session from '@/handler/session'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import moment from 'moment'   
  import PieChart from './chart/pie'    

  import FullCalendar from '@fullcalendar/vue'
  import dayGridPlugin from '@fullcalendar/daygrid'    
  import esLocale from '@fullcalendar/core/locales/es';
  import interactionPlugin from '@fullcalendar/interaction';

  export default {
    components: {                
      FullCalendar,
      PieChart,
    },        
    data: () => {
      return {      
        access: {
          module_id: Modules.EMPLEADOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'detailEmployeeHours',
          elements: {}
        }, 
        calendarPlugins: [ dayGridPlugin, interactionPlugin ],        
        calendarOptions: {
          locale: esLocale,
          event: []
        },
        table : {
          items: [],
          fields: [            
            {key: 'assignment', label: 'Asignación', class:"align-middle", width:"25%"},            
            {key: 'description', label: 'Detalle', class:"align-middle", width:"55%"},            
            {key: 'hours', label: 'Horas', class:"text-center align-middle", width:"20%"},
          ],        
        }, 
        total: {
          hoursProjectMonth: 0,
          hoursActivityMonth: 0,
        },
        dateSelected: moment().format('YYYY-MM-DD') ,       
        employeeSelectedID: 0,
        employeeSelected: {},
        chart: {
          loaded: false,
          chartdata: {},
        }, 
        detailMonthsEmployee: [],
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    }, 
    watch: {
      detailMonthsEmployee: function() {
        this.loadChartEmployee()
      },
    }, 
    mounted () {      
      this.employeeSelectedID = this.$route.params.employeeID  

      moment.locale('es');
      this.loadEmployeeSelected()        
    },      
    computed: {
      totalActivity: function() {
        var total = 0
        this.table.items.forEach(element => {
          if(element.activity_id) {
            total = total + parseFloat(element.hours)
          }          
        }) 
        return total       
      },       
      totalProjects: function() {
        var total = 0
        this.table.items.forEach(element => {
          if(element.projects_id) {
            total = total + parseFloat(element.hours)
          }          
        }) 
        return total       
      },      
    },
    methods: {
      dateClick(date) { 
        this.dateSelected = date.dateStr                 
        this.getHours()        
      },
      loadCalendar() {   
        let loader = this.$loading.show();

        this.calendarOptions.event = []

        var data = {          
          staff_id: this.employeeSelected.staff_id          
        }
        var result = serviceAPI.obtenerHorasByEmployee(data)

        result.then((response) => {                    
          var data = response.data

          data.projects.forEach(element => {
            var event = {}

            event = {
              title: 'Proyectos: ' + element.hours + ' hs',
              start: element.date,
              end: element.date,
              color: "#bae6c9",
            }            
            this.calendarOptions.event.push(event)            
          }); 
          
          data.activity.forEach(element => {
            var event = {}

            event = {
              title: 'Actividades: ' + element.hours + ' hs',
              start: element.date,
              end: element.date,
              color: "#ffeeba",
            }            
            this.calendarOptions.event.push(event)            
          });         

          loader.hide()
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error))
        });                     
      },
      getHours() {
        this.getHoursByDay()
        this.getHoursByMonth()        
      },
      getHoursByDay() {
        let loader = this.$loading.show();
        var data = {
          staff_id: this.employeeSelected.staff_id,
          date: this.dateSelected 
        }              
        var result = serviceAPI.obtenerHorasByDia(data)

        result.then((response) => {                    
          var data = response.data
          
          data.forEach(element => {
            if(element.projects_id) {
              element["_cellVariants"] = { assignment: 'success' }
            }
            else {
              element["_cellVariants"] = { assignment: 'warning' }
            }
            this.table.items.push(element)
          });
          
          this.table.items = data   
                              
          loader.hide()
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error))
        });
      }, 
      getHoursByMonth() {
        let loader = this.$loading.show();
        var data = {
          staff_id: this.employeeSelected.staff_id,
          date: this.dateSelected
        }              
        var result = serviceAPI.obtenerHorasByMes(data)

        result.then((response) => {                    
          var data = response.data
          this.detailMonthsEmployee = data          
          
          var totalProjects = 0
          var totalActivity = 0
          data.forEach(element => {            
            if(element.projects_id) {
              totalProjects = totalProjects + parseFloat(element.hours)
            } else {
              totalActivity = totalActivity + parseFloat(element.hours)
            }
          });

          this.total.hoursProjectMonth = totalProjects
          this.total.hoursActivityMonth = totalActivity
                    
          loader.hide()
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error))
        });
      },       
      loadEmployeeSelected(){
        let loader = this.$loading.show();
        var result = serviceAPI.mostrarEmpleado(this.employeeSelectedID)

        result.then((response) => {                            
          var data = response.data                            
          this.employeeSelected = data    
          
          this.loadCalendar()
          this.getHours()
          
          loader.hide()
        }) 
        .catch(error => {       
          loader.hide()   
          this.$awn.alert(ErrorToken.valid(error));
        }) 
      },

      loadChartEmployee() {     
        var data = this.formatDataChart()

        if(data.length) {                    
          var labels = []
          var datasets = [{
            backgroundColor: [],
            data: []
          }]          
                    
          data.forEach((item)=>{                         
            datasets[0].data.push(parseFloat(item.hours))
            datasets[0].backgroundColor.push("#" + Math.floor(Math.random()*16777215).toString(16))                        
            
            if(item.activity_id) {
              labels.push('ACTIVIDAD:' + item.activityName)
            } else {
              labels.push('PROYECTO:' + item.projectsName)
            }                      
          })
          
          this.chart.chartdata = {
            labels: labels,
            datasets: datasets,          
          }

          this.chart.loaded = true
        } else {
          this.chart.loaded = false            
        }                         
      },
      formatDataChart(data) {
        var arrData = []
        var data = this.detailMonthsEmployee
                
        if(data) {          
          data.forEach(element => {            
            let key = ''
            if(element.activity_id) {
              key = 'A' + element.activity_id
            } else {
              key = 'P' + element.projects_id
            }

            if(arrData.hasOwnProperty(key)) {
              arrData[key].hours = arrData[key].hours + parseFloat(element.hours)
            } else {              
              arrData[key] = []
              arrData[key] = {
                hours: parseFloat(element.hours),
                activity_id: element.activity_id,
                projects_id: element.projects_id,
                projectsName: (element.projects ? element.projects.name : ''),
                activityName: (element.activity ? element.activity.name : ''),
              }                
            }
                          
          });
        }
        
        var arrTMP = []
        for (const prop in arrData) {
          arrTMP.push(arrData[prop])
        }               

        return arrTMP
      }
    }
  }
</script>
<style>
  .employee-detail-hours-badge {
    font-size: 13px;
  }
</style>